.why_choose_banner img{
	height: 45vh;
	width: 25vw;
  }
  .subheading{
	font-size: var(--secondary_font_size);
	font-weight: 600;
  }
  .y-us-section {
	padding: 82px 0 82px;
  }
  .y-us-head {
	text-align: center;
	margin: 0 0 91px;
  }
 
  .why_us .why_us-box {
	margin-bottom: 18px;
  }
  .why_us .why_us-box .iconset_right {
	float: right;
	text-align: center;
	width: 15%;
  }
  .why_us  .iconset_right img {
	width: 5vw;
  }

  .why_us  .iconset_left img {
	width: 5vw;
  }
  .why_us .why_us-box .iconset_left {
	float: left;
	text-align: center;
	width: 15%;
  }
  .why_us .why_us-box .iconset i {
	color: #000;
	font-size: 44px;
  }
  .why_us .why_us-box .y-us-content_right {
	float: right;
	width: 75%;
  }
  .why_us .why_us-box .y-us-content_left {
	float: left;
	width: 75%;
	text-align: end;
  
	
  
  }
  .why_us .why_us-box .y-us-content h4 {
	color: #3a3a3a;
	font-size: 18px;
	letter-spacing: 0;
	line-height: 22px;
	margin: 14px 0 12px;
	text-transform: uppercase;
  }
  .why_us .why_us-box .y-us-content p {
	color: #777777;
	font-size: 13px;
	font-weight: 300;
	line-height: 24px;
  }
	/* responsive */

	@media (min-width: 1350px) {
		.why_us .iconset_right img,
		.why_us .iconset_left img {
			max-width: 80px; /* Smaller size for mobile devices */
		}

.why_choose_banner img{
	height: 100%;
	width: 100%;
}
	}

	/* @media screen and (max-width: 1700px) {
		

.why_choose_banner img{
	height: 45vh;
	width: 25vw;
	
	  }} */