
.hero-section {
	background-image: url('../../../public/img/hero_tjaova.avif');
	/* Update the path */
	background-size: cover;
	background-position: center;
	display: flex;
	/* justify-content: center; */
	align-items: center;
	height: 100vh;
	color: #000;
	position: relative;
}


.hero-section::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.7);
	/* White overlay with transparency */
	z-index: 1;
}

.content {
	position: relative;
	z-index: 2;
	/* text-align: center; */
	max-width: 800px;
	padding: 66px;
}

.banner_title {
	font-size: 55px;
	font-weight: 600;
	margin: 0;
	line-height: 4.5rem;
}
.banner_text{
	font-size: 16px;
    font-weight: 400;
    color: #4f4b4b;
}
