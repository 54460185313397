.portfolio-section {
    padding: 20px;
    text-align: center;
  }

  
  .portfolio-header h2 {
    font-size: 1.5em;
   
  }
  
  .porfolio-subtext {
    
    max-width: 750px;
    margin: 0 auto;
  }
  
  .portfolio-section .image-section1{
    border-radius: 15px;
    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
    flex: 1 1;
    background-color:#ffffff;


  }
  .portfolio-image1{
    background-color:#ffffff;
    max-width: 100%;
    border-radius: 12px;
  }
.portfolio-contant-box{
  text-align: justify;
  padding: 0px 45px;
}
  .portfolio-button{
    background-color: #494949;
    color: #ffffff;
    border: none;
    padding: 10px 25px;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 30px;
	transition: background-color 0.3s;
    
  }

  .portfolio-button:hover{
    background-color: #686767;
  }

  .portfolio-section .image-section2{
    /* border-radius: 15px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
    flex: 1 1;
    background-color: #ffffff;

  }
  .portfolio-image2{
    background-color: #ffffff;
    max-width: 100%;
    border-radius: 12px;
  }

  .portfolio-logo-section {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .portfolio-logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .portfolio-logo-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }


  .portfolio-title {
    font-size:var(--secondary_font_size);
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
  }
  
 

  