.faq-container {
	width: 85%;
	margin: auto;
	
  }
  
  
  
  .faq-item {
	border-bottom: 1px solid #ddd;
	padding: 17px 0;
  }
  
  .faq-question {
	display: flex;
	justify-content: space-between;
	cursor: pointer;
  }
  
  .faq-question h3 {
	font-size: 22px;
	color: #333;
  }
  
  .faq-answer {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease;
  }
  
  .faq-answer.show {
	max-height: 200px;
  }
  
  .faq-answer p {
	margin: 10px 0;
	color: #555;
	font-size: 20px;


  }