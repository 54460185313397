.service-item-it {
	position: relative;
	height: 350px;
	padding: 30px 20px;
	background: #FFFFFF;
	box-shadow: 0 0 45px rgba(0, 0, 0, .08);
	transition: .5s;
  }
  .services_heading{
	font-size: var(--heading_font_size);
	font-weight: 600;
  }
  .services_subheading{
	font-size: var(--primary_font_size);
  }
  .sevices_title{
	font-size: 20px;
	font-weight: 600;
  }
  .services_text{
	font-size: 15px;
	font-weight: 400;
  }
  
  .service-item-it:hover {
	/* background: var(--primary); */
	/* background: linear-gradient(180deg, rgba(18, 15, 45, 0), #120f2d); */
    color:#000000;
  }
  
  .service-item-it .service-icon {
	margin: 0 auto 20px auto;
	width: 90px;
	height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--dark);
	/* background: url(../public/img/icon-shape-primary.png) center center no-repeat; */
	transition: .5s;
  }
  
  .service-item-it:hover .service-icon {
	color: var(--primary);
	/* background: url(../public/img/icon-shape-white.png); */
  }
  
  .service-item-it h5,
  .service-item-it p {
	transition: .5s;
  }
  
  .service-item-it:hover h5,
  .service-item-it:hover p {
	color: var(--light);
  }
  
  .service-item-it a.btn {
	position: relative;
	display: flex;
	color: #000;
	transition: .5s;
	z-index: 1;
  }
  
  .service-item-it:hover a.btn {
	color:  var(--primary);
  }
  
  .service-item-it a.btn::before {
	position: absolute;
	content: "";
	width: 35px;
	height: 35px;
	top: 0;
	left: 0;
	border-radius: 35px;
	/* background: #DDDDDD; */
	transition: .5s;
	z-index: -1;
  }
  
  .service-item-it:hover a.btn::before {
	width: 100%;
	background: var(--light);
  } 