.use_tech_container{
	background-color: var(--dark);
  }

  .use_tech .css-1wxkzlj-MuiTabs-flexContainer {
	display: flex !important;
	justify-content: space-around !important;
  }
  
  .use_tech .css-heg063-MuiTabs-flexContainer {
   
	display: flex !important;
   
	justify-content: space-around !important;
	
  }
  
  .use_tab{
	width: 165px;
	height: 140px;
	background-color: #fff;
	border-radius: 8px;
  
  }
  .use_tech .css-162tvoi {
    display: flex !important;
    justify-content: space-between !important;
}



/* .css-162tvoi {
    display: flex;
    justify-content: space-between;
} */