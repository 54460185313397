
.swiper-container,
.swiper-wrapper {
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.swiper-slide {
  display: flex;
  align-items: center;
  width: auto;
  margin-right: 8px;
  gap: 20px;
  img {
    max-width: 600px;
    max-height: 500px;
  }
}

.projectInfo {
  position: relative;
  margin: 14px 0 13px 0;
  position: relative;
  z-index: -1;
  text-transform: uppercase;
  h2 {
    margin: 0;
  }
  h3 {
    margin: 8px 8px 8px 0;
  }
  .category,
  .medium {
    text-transform: none;
    opacity: 0.4;
  }
  .medium {
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 100px;
    font-style: italic;
    padding: 0px 7px 1px 7px;
  }
}


.thumbContainer {
  opacity: 1;
  transform: translateY(60%);
  img,
  video {
    display: block;
    position: relative;
    max-width: calc(380px + 43vh);
    max-height: calc(255px + 43vh);
  }
}

.fadeInSlide {
  animation-name: fadeInSlide;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(1, 0.055, 0.32, 1);
  animation-fill-mode: forwards;
}

@keyframes fadeInSlide {
  0% {
    transform: translateY(60%);
  }
  100% {
    transform: translateY(0);
  }
}
