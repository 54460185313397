
.footer {
	background: url(../../../public/img/footer.png) center center no-repeat;
	background-size: contain;
	margin-top: 5%;
}

.overlay-top::before,
.overlay-bottom::after {
	position: absolute;
	content: "";
	width: 100%;
	height: 85px;
	left: 0;
	z-index: 1;
}

.overlay-top::before {
	top: 0;
	background: url(../../../public/img/overlay-top.png) top center no-repeat;
	background-size: contain;
}

.overlay-bottom::after {
	bottom: 0;
	background: url(../../../public/img/overlay-bottom.png) bottom center no-repeat;
	background-size: contain;
}

.footer_title {
	font-size: 24px;
	text-decoration: none;
	color: #fff;
	font-weight: 600;

}

.footer_text {
	color: #ffffff9c;
	font-size: 16px;
}

.footer .btn.btn-social {
	margin-right: 5px;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgba(255, 255, 255, .5);
	border: 1px solid rgba(256, 256, 256, .1);
	border-radius: 40px;
	transition: .3s;
}

.footer .btn.btn-social:hover {
	color: #042668;
}

.footer .btn.btn-link {
	display: block;
	margin-bottom: 10px;
	padding: 0;
	text-align: left;
	color: rgba(255, 255, 255, .5);
	font-weight: normal;
	transition: .3s;
	text-decoration: none;
}

.footer .btn.btn-link:hover {
	color: #FFFFFF;
}

.footer .btn.btn-link::before {
	position: relative;
	/* content: "\f105";
    font-family: "Font Awesome 5 Free"; */
	font-weight: 900;
	margin-right: 10px;

}

.btn {
	font-weight: 500;
	transition: .5s;
}

.btn-square {
	width: 38px;
	height: 38px;
}

.btn-sm-square {
	width: 32px;
	height: 32px;
}

.back-to-top {
	position: fixed;
	display: none;
	right: 45px;
	bottom: 45px;
	z-index: 99;
}

.btn-lg-square {
	width: 48px;
	height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: normal;
	border-radius: 50px;
	text-decoration: none;
}

.footer .btn.btn-link:hover {
	letter-spacing: 1px;
	box-shadow: none;
}

.footer .copyright {
	padding: 25px 0;
	font-size: 14px;
	border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
	color: rgba(255, 255, 255, .5);
	text-decoration: none;
}

.footer .copyright a:hover {
	color: #FFFFFF;
}

.footer .footer-menu a {
	margin-right: 15px;
	padding-right: 15px;
	border-right: 1px solid rgba(255, 255, 255, .1);
	text-decoration: none;
}

.footer .footer-menu a:last-child {
	margin-right: 0;
	padding-right: 0;
	border-right: none;
}