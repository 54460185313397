
.productcontainer {
	background: #dfdfdf;
  }
  
  .section-2 {
	height: calc(300vh + 32px + 16px);
	display: grid;
	grid-template-columns: 40% 60%;
  }
  
  .pinned-quote .quote {
	font-size: 5vmin;
	padding: 16px 16px 0 16px;
  }
  
  .pinned-quote cite {
	display: block;
	margin-top: 8px;
	font-style: italic;
  }
  
  .images-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 0 8px;
	gap: 8px;
	margin-top: 16px;
  }
  
  .images-container img {
	width: 100%;
	max-height: 75vh;
	height: 100%;
	object-fit: cover;
	border-radius: 8px;
  }