.product_container{
	overflow: hidden;
	
}
 .product_container .pin-wrap img {
  height: 50vh; 
  width: auto;
  object-fit: cover;
}


.product_container .horizontal-section {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center; 
  background: var(--text-color);
  color: var(--bg-color);
}


.product_container .pin-wrap {
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10vw;
  gap: 1rem; 
  width: max-content; 
}


.product_container .pin-wrap > * {
  flex-shrink: 0; 
  width: 40vw; 
  padding: 0px; 
}


