

.custom-box {
	border-radius: 15px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Adjust values as needed */
	padding: 20px;
	background-color: white; /* Optional: to make the background stand out */
  }
  
  
  
  .image-section {
	border-radius: 15px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	flex: 1;
  }
  
  .employee-image {
	max-width: 100%;
	border-radius: 12px;
  }
  
  
  .benefits-container {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	padding: 20px;
  }
  
  .benefit-card {
	background-color: #fff; /* Light blue background */
	border-radius: 10px;
	padding: 20px;
	width: 30%; /* Adjust this as needed for responsiveness */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: transform 0.2s ease-in-out;
  }
  .benefit-card:hover {
	transform: translateY(-5px);
  }
  
  .benefit-icon {
	background-color: white;
	padding: 10px;
	border-radius: 50%;
	margin-bottom: 15px;
  }
  
  .benefit-card h3 {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 10px;
  }
  
  .benefit-card p {
	font-size: 14px;
	line-height: 1.5;
	color: #333;
  }
  
  .job-openings-container {
	text-align: center;
	padding: 40px 20px;
  }
  
  
  
  .job-list-container {
	display: flex;
	justify-content: center;
	gap: 20px;
  }
  
  .job-list-column {
	background-color: #fff;
	border-radius: 10px;
	padding: 20px;
	width: 50%; /* Adjust width as per layout */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: transform 0.2s ease-in-out;
  }
  .job-list-column:hover{
	transform: translateY(-5px);
  }
  
  .job-item {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
  }
  
  .job-icon {
	background-color: white;
	border-radius: 50%;
	padding: 10px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .job-title {
	font-size: 18px;
	font-weight: 500;
  }
  
  .roles-container {
	text-align: center;
	padding: 40px 20px;
  }
  
  .roles-list-container {
	display: flex;
	justify-content: center;
	gap: 20px;
  }
  
  .roles-list-column {
	background-color: #fff;
	border-radius: 10px;
	padding: 50px 30px;
	width: 50%; /* Adjust width as per layout */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: transform 0.2s ease-in-out;
  }
  .roles-list-column:hover{
	transform: translateY(-5px);
	border: 1px solid grey;
  }
  
  .roles-item {
	display: flex;
	/* align-items: center; */
	flex-direction: column;
	margin-bottom: 15px;
  }
  .roles-item p{
	text-align: start;
  }
  
  
  .roles-title {
	font-size: 20px;
	font-weight: 700;
	text-align: start;
  }
  
  
  .job-list {
	display: flex;
	flex-direction: column;
	gap: 20px;
  }
  
  .job-card {
	cursor: pointer;
	border: 1px solid #ddd;
	padding: 20px;
	border-radius: 5px;
	background-color: #fff;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	/* transition: transform 0.2s ease-in-out; */
  }
  .job-card:hover{
	/* transform: translateY(-5px); */
	background-color: #f6f6f6;
  }
  
  .job-card h3 {
	margin-top: 0;
  }
  
  .job-card p {
	margin: 5px 0;
  }
  .apply-button {
	background-color: var(--primary); /* Bootstrap primary color */
	color: white;
	border: none;
	padding: 8px 20px;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;
	float:right;
  }
  
  .apply-button:hover {
	background-color: #e65a2a; /* Darker shade for hover effect */
  }
  .services_subheading strong {
    font-weight: 600;   /* Make text bold */
    color: #171717;      /* Change text color to blue (or any preferred color) */
    font-size: 16px;     /* Adjust font size */
    margin-right: 5px;   /* Add some space after the text */
}

  
  .container {
	max-width: 1200px; /* Set a max width for better alignment */
	margin: 0 auto; /* Center the container */
  }
  
  .form-control, .form-select {
	border-radius: 5px; /* Rounded corners */
	border: 1px solid #ccc; /* Border color */
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
   padding-left: 30px;
  
  }
  
  .form-control:focus, .form-select:focus {
	border-color: var(--primary); /* Bootstrap primary color */
	box-shadow: 0 0 0 0rem #e65a2a; /* Focus shadow */
  }
  
  input::placeholder {
	color: #999; /* Placeholder color */
	opacity: 1; /* Ensures full opacity */
  }
  
  @media (max-width: 768px) {
	.col-lg-4, .col-md-6 {
	  flex: 0 0 100%; /* Stack columns on smaller screens */
	  max-width: 100%; /* Full width */
	}
  }
  .position-relative .fas.fa-search {
	font-size: 16px; /* Adjust the size if needed */
  }