button {
    cursor: pointer;
    padding: 0 6px;
    min-width: 88px;
    min-height: 36px;
  }
  
  .controls {
    padding: 24px;
  }
  
  .modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.15);
    opacity: 0;
    visibility: hidden;
  }
  
  .modal-dialog {
    /* width: 70vmin;
    height: 70vmin; */
    position: relative;
    overflow: hidden;
  }
  
  .modal-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .modal-polygon {
    fill: var(--primary);
  }
  
  .modal-content {
    position: relative;
    top: 0;
    left: 0;
    padding: 24px;
    visibility: hidden;
    opacity: 0;
    color: #000;
    width: 100%;
    height: 100%;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: #000000;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: rgb(0, 0, 0);
  }
  
  .loading-container {
    display: flex;               /* Use flexbox for centering */
    justify-content: center;     /* Center horizontally */
    align-items: center;         /* Center vertically */
                   /* Full height of the modal content */
  }
  
  