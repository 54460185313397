.solution_container{
	background-color: var(--dark);
}

.slick-dots li.slick-active button:before {
	font-size: 16px !important;
	color: #ffffff !important;
	margin-top: 20px;
  }
  
  .slick-dots li button:before{
	color: #ffffff !important;
	margin-top: 20px;
  }