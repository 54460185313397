
.client-section {
	text-align: center;
	margin: 40px 0;
  }
  
  .client_section_text {
	font-size: 40px;
	font-weight: 500;
	
  }
  

  
  .cta-button {
	background-color:var(--primary); /* Yellow background */
	color: #fff; /* Dark blue text */
	padding: 10px 20px;
	border: none;
	border-radius: 30px;
	font-size: 16px;
	cursor: pointer;
	margin-top: 20px;
	font-weight: bold;
  }
  
  .cta-button:hover {
	background-color: #e65a2a; /* Darker yellow on hover */
  }
  
  
  .project-section {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--dark);
	padding: 20px;
	color: white;
  }
  
  .project-content {
	max-width: 50%;
  }
  
  .project_content_text {
	font-size: 30px;
	
	color: #fff;
  }
  
  
  .project_highlight{
	color: #fff;
	font-weight: bold;
  }
  
  
  .project-image {
	max-width: 60%;
  }
  
  .project-image img {
	max-width: 60%;
	height: auto;
  }
  


