

.video_it
{
 position: relative;
 height: 95%;
 min-height: 200px;
 background: linear-gradient(rgba(15, 23, 43, .1), rgba(15, 23, 43, .1)), url(../../../public/img/video.png);
 background-position: center center;
 background-repeat: no-repeat;
 background-size: cover;
 border-radius: 10px;
 box-shadow: 0px 0px 12px #000000e6;

}

.video-container {
    width: 100%; /* or your desired width */
    height: auto; /* maintain aspect ratio */
    overflow: hidden; /* avoid overflow */
  }
  

.video_it .btn-play {
 position: absolute;
 z-index: 3;
 top: 50%;
 left: 50%;
 transform: translateX(-50%) translateY(-50%);
 /* box-sizing: content-box; */
 display: block;
 width: 32px;
 height: 72px;
 border-radius: 50%;
 border: none;
 outline: none;
 padding: 18px 20px 18px 28px;
}

.video_it .btn-play:before {
 content: "";
 position: absolute;
 z-index: 0;
 left: 50%;
 top: 50%;
 transform: translateX(-50%) translateY(-50%);
 display: block;
 width: 100px;
 height: 100px;
 background: var(--primary);
 border-radius: 50%;
 animation: pulse-border 1500ms ease-out infinite;
}

.video_it .btn-play:after {
 content: "";
 position: absolute;
 z-index: 1;
 left: 50%;
 top: 50%;
 transform: translateX(-50%) translateY(-50%);
 display: block;
 width: 100px;
 height: 100px;
 background: var(--primary);
 border-radius: 50%;
 transition: all 200ms;
}

.video_it .btn-play img {
 position: relative;
 z-index: 3;
 max-width: 100%;
 width: auto;
 height: auto;
}

.video_it .btn-play span {
 display: block;
 position: relative;
 z-index: 3;
 width: 0;
 height: 0;
 border-left: 32px solid var(--dark);
 border-top: 22px solid transparent;
 border-bottom: 22px solid transparent;
}
/* Shared styles for modals */
#videoModal {
 z-index: 99999;
}

#videoModal .modal-dialog {
 position: relative;
 max-width: 800px;
 margin: 60px auto 0 auto;
}

#videoModal .modal-body {
 position: relative;
 padding: 0px;
}

#videoModal .close {
 position: absolute;
 width: 30px;
 height: 30px;
 right: 0px;
 top: -30px;
 z-index: 999;
 font-size: 30px;
 font-weight: normal;
 color: #FFFFFF;
 background: #000000;
 opacity: 1;
}